const InvitationIcon = () => {
    return (
        <span role="img" className="anticon">
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6 .25a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-3 0v-4.5A1.5 1.5 0 0 1 6 .25zm0 8.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
                    fill="#FA8C16"
                    fillRule="evenodd"
                />
            </svg>
        </span>
    );
};
export default InvitationIcon;
