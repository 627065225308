import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import Navbar from "react-bootstrap/Navbar";

import { logout, triggerCrossTabLogout } from "../../utils/Auth";

const Header = ({ email }) => {
    const navigate = useNavigate();
    const logoutHandler = () => {
        // call generic logout process
        triggerCrossTabLogout();
        logout();
        // redirect
        navigate("/AdminDashboard/Login");
    };

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <a onClick={logoutHandler}>Sign out </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <header id="avb-header">
            <div className="header_Styles">
                <Navbar>
                    <Navbar.Brand href="/AdminDashboard">
                        AvalonBay
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            <span>
                                {" "}
                                {sessionStorage.getItem("email") || email}
                            </span>
                            <Dropdown overlay={menu} trigger={["click"]}>
                                <a className="ant-dropdown-link cstm-signout-link">
                                    <DownOutlined />
                                </a>
                            </Dropdown>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </header>
    );
};

export default Header;
