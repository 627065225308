export const initialState = {
  isFetchingApplications: true,
  applications: [],
  helpRequests: [{}],
  application: {},
  selectedActionId: null,
  filterOptions: {
    userFilterSelections: {},
  },
  notification: undefined,
};

export interface ReduxState {
  isFetchingApplications: boolean;
  applications: [];
  helpRequests: [{}];
  application: {};
  selectedActionId: null;
  filterOptions: {
    userFilterSelections: {};
  };
  notification?: {
    type?: string;
    message?: string;
  };
}
function computeReadyToFinish(data) {
  const cancelled =
    data && data.applicationStatus?.toUpperCase() === "CANCELLED";

  const completed =
    data && data.enableDocusign
      ? ["LEASE SIGNED", "LEASE EXPIRED", "LEASE DECLINED"].includes(
          data.applicationStatus.toUpperCase()
        )
      : false;

  const readyToFinishCount = cancelled || completed ? 1 : 0;

  return readyToFinishCount;
}

const store = (state = initialState, action) => {
  switch (action.type) {
    case "SET_APPLICATIONS":
      // set the ready to finish and inc stale here
      const apps = action.data.applications.map((x) => {
        // ready to finish
        x.readyToFinishCount = computeReadyToFinish(x);

        // incomplete stale
        const incTimeMin = 64800000; // 18hrs
        const greaterThanIncTimeMin =
          x.modifiedDate &&
          new Date().getTime() - new Date(x.modifiedDate).getTime() >
            incTimeMin;
        const incAppStatus = [
          "INVITE SENT" /* 0 */,
          "IN PROGRESS" /* 1 */,
          "ACTION REQUIRED" /* 12 */,
        ].includes(x.applicationStatus.toUpperCase());
        const incGroupStatus = [
          2 /* Approved With Conditions */, 3 /* Declined */,
        ].includes(x.applicationScreeningResultId);

        x.incStaleItemsCount =
          greaterThanIncTimeMin && (incAppStatus || incGroupStatus) ? 1 : 0;

        // remove dashes and capitalize
        x.communityName = x.communityName
          .replace(/-/g, " ")
          .split(" ")
          .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
          .join(" ");

        x.completeUnitId = `${x.phaseId ?? ""}-${x.buildingId ?? ""}-${
          x.unitId ?? ""
        }`;

        return x;
      });

      return {
        ...state,
        applications: apps,
        isFetchingApplications: false,
      };

    case "GET_APPLICATIONS":
      return {
        ...state,
      };

    case "SET_APPLICATION":
      action.data.readyToFinishCount = computeReadyToFinish(action.data);
      return {
        ...state,
        application: action.data,
      };

    case "GET_APPLICATION":
      return {
        ...state,
      };

    case "SET_INITIAL_FILTER_OPTIONS":
      return {
        ...state,
        filterOptions: action.data,
      };

    case "SET_FILTER_OPTIONS":
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          userFilterSelections: {
            ...state.filterOptions.userFilterSelections,
            ...action.data,
          },
        },
        isFetchingApplications: true,
      };

    case "GET_FILTER_OPTIONS":
      return {
        ...state,
      };

    case "SET_SEARCH_TERMS":
      return {
        ...state,
        searchTerms: action.data,
      };

    case "SET_ACTION_REQUIRED_ID":
      if (action.data === state.selectedActionId) {
        return {
          ...state,
          selectedActionId: null,
        };
      } else {
        return {
          ...state,
          selectedActionId: action.data,
        };
      }
    case "SET_HELP_REQUESTS":
      // current data
      const helpRequests: any[] = [...state.helpRequests];
      const applications = state.applications;
      const incomingHelpRequests = action.data.helpRequests;

      incomingHelpRequests.forEach((helpRequest) => {
        const isNew = helpRequest.helpStatus === "NEW";

        // get current help requests
        const current = helpRequests.filter(
          (x: any) => x.applicationId === helpRequest.applicationId
        );

        if (current.length === 0) {
          if (!isNew) return;
          helpRequests.push({
            applicationId: helpRequest.applicationId,
            helpRequestList: [helpRequest],
          });
        } else {
          const { helpRequestList } = current[0];
          let isMatchFound = false;

          // replace
          for (let ctr = 0; ctr < helpRequestList.length; ctr++) {
            if (helpRequestList[ctr].id === helpRequest.id) {
              helpRequestList[ctr] = helpRequest;
              isMatchFound = true;
              break;
            }
          }

          if (!isMatchFound) {
            if (!isNew) return;
            helpRequestList.push(helpRequest);
          }
        }
      });

      // count help requests
      helpRequests.forEach((helpRequest: any) => {
        const application: any[] = applications.filter(
          (x: any) => x.applicationId === helpRequest.applicationId
        );
        if (!application || !application.length) return;

        const totalHelpRequestsCount = helpRequest.helpRequestList.length;
        application[0].helpRequestCount = totalHelpRequestsCount;
      });

      return {
        ...state,
        helpRequests: [...helpRequests],
        applications: applications,
      };

    case "RESET_HELP_REQUESTS":
      return {
        ...state,
        helpRequests: [],
      };

    case "REMOVE_HELP_REQUEST":
      // On Applications Details page
      const requestId = action.data.id;
      const requests = state.helpRequests.map((x: any) => {
        if (x.helpRequestList) {
          x.helpRequestList.forEach((request) => {
            if (request.id === requestId) {
              request.helpStatus = "RESOLVED";
            }
          });
          return { ...x };
        } else {
          return x;
        }
      });

      // On Applications page, remove one from the application sum also so the Actions Required works correctly
      if (state.applications.length > 0) {
        let helpRequestApps = state.applications;
        const appId = action.data.applicationId;
        const app: any = helpRequestApps.find(
          (x: any) => x.applicationId === appId
        );
        if (app) {
          app.helpRequestCount = app.helpRequestCount - 1;
        }

        return {
          ...state,
          helpRequests: requests,
          applications: helpRequestApps,
        };
      }

      return {
        ...state,
        helpRequests: requests,
      };

    case "SET_APPLICATION_STATUS":
      return {
        ...state,
        applicationStatus: action.payload,
      };
    case "SET_APPLICATION_TERMS_AND_CONDITIONS":
      return {
        ...state,
        applicationTermsAndConditions: action.payload,
      };
    case "SET_NOTIFICATION":
      return {
        ...state,
        notification: action.payload,
      };
    case "RESET_NOTIFICATION":
      return {
        ...state,
        notification: undefined,
      };
    case "GET_APPLICATION_HOLD_FROM_NOW":
      var data = action.data;
      return {
        ...state,
        getApplicationHoldFromNow: data,
      };
    default:
      return { ...state };
  }
};

export default store;
