require("./LeaseProcess.scss");
import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { convertToLocalAndFormatDate, formatTime } from "../../utils/Utils";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Application, ApplicationStatusText } from "../../types/Application";
import { LeaseEventId } from "../../types/Application";
import GenerateLeaseModal from "./GenerateLeaseModal";
import StatusTag from "../Shared/StatusTag";
import { postLease } from "../../services/LeaseService";

function getGeneratedDate(leaseGeneratedDate) {
  return leaseGeneratedDate
    ? `${formatTime(leaseGeneratedDate)}, ${convertToLocalAndFormatDate(
        leaseGeneratedDate
      )}`
    : "";
}

interface LeaseProcessProps {
  application: Application;
}

const LeaseProcess = ({ application }: LeaseProcessProps) => {
  const { notification } = useSelector((state: RootStateOrAny) => state);
  const { applicationId } = application ?? {};
  const [showGenerateLeaseModal, setShowGenerateLeaseModal] = useState(false);

  const validStatuses = [
    ApplicationStatusText.LEASE_READY,
    ApplicationStatusText.LEASE_PENDING,
    ApplicationStatusText.LEASE_SIGNED,
    ApplicationStatusText.LEASE_EXPIRED,
    ApplicationStatusText.LEASE_DECLINED,
  ];

  useEffect(() => {
    const { origin } = notification ?? {};
    if (origin === "postLease") {
      window.scrollTo(0, 0);
    }
  }, [notification]);

  if (!validStatuses.includes(application?.applicationStatus)) {
    return null;
  }
  const { Panel } = Collapse;

  const lastEvent = application?.lease?.leaseEvents?.[0] ?? null;
  const leaseGeneratedDate =
    application?.leaseGeneratedDate ||
    (application?.applicationStatus === ApplicationStatusText.LEASE_READY &&
      lastEvent?.leaseEventId === LeaseEventId.LeaseGenerated &&
      lastEvent?.createdDate) ||
    "";

  return (
    <div id="avb-lease-process">
      <Collapse defaultActiveKey={"2"} bordered={false}>
        <Panel header={`LEASE GENERATION PROCESS`} key="2">
          <div className="row">
            <div className="blue-card col-sm-12 col-md-4">
              <h3>Lease Generation Process</h3>
              <p>LEASE STATUS</p>
              {application && application.applicationStatus && (
                <p className="tagPlaceHolder">
                  <StatusTag name={application?.applicationStatus} />
                </p>
              )}
              {leaseGeneratedDate && (
                <p>
                  LEASE GENERATED TIME
                  <span>{getGeneratedDate(leaseGeneratedDate)}</span>
                </p>
              )}
            </div>
            {!!application?.lease && (
              <div className="col-sm-12 col-md-8 align-self-end">
                <div className="col lease-button_container">
                  <LeaseButton
                    applicationId={applicationId}
                    applicationStatus={application?.applicationStatus}
                    lease={application?.lease}
                    onClick={() => setShowGenerateLeaseModal(true)}
                  />
                </div>
              </div>
            )}
          </div>
        </Panel>
      </Collapse>
      <GenerateLeaseModal
        selectedApplication={application}
        onClose={() => setShowGenerateLeaseModal(false)}
        show={showGenerateLeaseModal}
      />
    </div>
  );
};

const LeaseButton = ({ applicationId, applicationStatus, lease, onClick }) => {
  if (applicationStatus == null || applicationId == null) {
    return null;
  }

  const dispatch = useDispatch();

  const resendLease = () => {
    dispatch(postLease(applicationId, LeaseEventId.LeaseResent));
  };

  const viewLease = () => {
    const lastEvent = lease?.leaseEvents?.[0] ?? null;
    if (!!lastEvent) {
      const { dsDocPath = null } = lastEvent;
      dsDocPath && window.open(dsDocPath, "_blank");
    }
  };

  if (lease?.canGenerateLease) {
    return (
      <Button
        data-test-id="generate-lease-button"
        variant="dark"
        onClick={onClick}
      >
        Generate Lease in DocuSign
      </Button>
    );
  } else if (lease?.canResendLease) {
    return (
      <Button
        data-test-id="resend-lease-button"
        variant="outline-dark"
        onClick={resendLease}
        style={{
          marginRight: "15px",
        }}
      >
        Resend Email
      </Button>
    );
  }

  if (applicationStatus === ApplicationStatusText.LEASE_SIGNED) {
    return (
      <Button
        variant="dark"
        onClick={viewLease}
        data-test-id="view-lease-button"
      >
        View Lease
      </Button>
    );
  }

  return null;
};

export default LeaseProcess;
