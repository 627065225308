const ExclamationIcon = () => {
    return (
        <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.5.25A1.5 1.5 0 0 1 8 1.75v4.5a1.5 1.5 0 0 1-3 0v-4.5A1.5 1.5 0 0 1 6.5.25zm0 8.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
                fill="#FA8C16"
                fillRule="evenodd"
            />
        </svg>
    );
};
export default ExclamationIcon;
