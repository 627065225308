import { getConfigs } from "../configs/Config";
import { fetchIdToken } from "../utils/Auth";
import LocalStorageService from "./LocalStorageService";
import { setApplication, setNotification } from "../store/actions";
import { getApplicationById } from "./AdminDashService";
import { LeaseEventId, PostLeaseResponse } from "../types/Application";

export const postLease = (applicationId, leaseEventId) => {
  const { applicationBaseRoute, applicationApiKey } = getConfigs();
  const route = `${applicationBaseRoute}/${applicationId}/Lease`;

  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      "x-id-token": fetchIdToken()?.replace("Bearer ", "") || "",
      "x-api-key": applicationApiKey || "",
    });

    const body = JSON.stringify({
      applicationId,
      leaseEventId,
    });

    const options = {
      method,
      headers,
      body,
    };

    return fetch(route, options)
      .then((data) => data.json())
      .then((data: PostLeaseResponse) => {
        if (leaseEventId === LeaseEventId.LeaseGenerated) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Lease has been generated and successfully sent out`,
              origin: "postLease",
            })
          );
        } else if (leaseEventId === LeaseEventId.LeaseResent) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Lease has been successfully sent out`,
              origin: "postLease",
            })
          );
        }

        const { application: applicationFromLocal } =
          LocalStorageService.getApplication(applicationId);

        const newApplicationData = {
          ...(applicationFromLocal ?? {}),
          lease: {
            ...(applicationFromLocal?.lease || {}),
            leaseEvents: [
              data,
              ...(applicationFromLocal?.lease?.leaseEvents || []),
            ],
          },
        };

        dispatch(setApplication(newApplicationData));
        LocalStorageService.setApplicationInLocal(newApplicationData, null);
        dispatch(getApplicationById(applicationId));
      })
      .catch((error) => {
        if (leaseEventId === LeaseEventId.LeaseGenerated) {
          dispatch(
            setNotification({
              type: "ERROR",
              message: `Error generating lease, please try again`,
              origin: "postLease",
            })
          );
        } else if (leaseEventId === LeaseEventId.LeaseResent) {
          dispatch(
            setNotification({
              type: "ERROR",
              message: `Error sending out lease, please try again`,
              origin: "postLease",
            })
          );
        }
        console.log("error on postLease ", error);
      });
  };
};
