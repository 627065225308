require("./ActionRequired.scss");
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import Action from "./Action";
import RedFlagIcon from "../Shared/icons/RedFlagIcon";
import {
    filterBySearch,
    getHelpRequestsSum,
    getIncStaleItemsSum,
    getReadyToFinishSum,
} from "../../utils/Utils";
import { setActionRequiredId } from "../../store/actions";

const ActionRequired = () => {
    const applications = useSelector(
        (state: RootStateOrAny) => state.applications
    );
    const searchTerms = useSelector(
        (state: RootStateOrAny) => state.searchTerms
    );
    const selectedActionId = useSelector(
        (state: RootStateOrAny) => state.selectedActionId
    );
    const helpRequests = useSelector(
        (state: RootStateOrAny) => state.helpRequests
    );
    const dispatch = useDispatch();

    const helpRequestsSum =
        (applications &&
            helpRequests &&
            getHelpRequestsSum(filterBySearch(applications, searchTerms))) ||
        0;
    const incStaleItemsSum =
        (applications &&
            getIncStaleItemsSum(filterBySearch(applications, searchTerms))) ||
        0;
    const readyToFinishSum =
        (applications &&
            getReadyToFinishSum(filterBySearch(applications, searchTerms))) ||
        0;
    const actions = [
        {
            id: "avb-actions-total",
            text: "Total \n Actions",
            value: helpRequestsSum + incStaleItemsSum + readyToFinishSum,
        },
        {
            id: "avb-actions-help-requests",
            text: "Help \n Requests",
            value: helpRequestsSum,
        },
        {
            id: "avb-actions-inc-stale-items",
            text: "Incomplete \n Stale Items",
            value: incStaleItemsSum,
        },
        {
            id: "avb-actions-ready-to-finish",
            text: "Ready to \n FINISH",
            value: readyToFinishSum,
        },
    ];

    const onActionClick = (actionId) => {
        dispatch(setActionRequiredId(actionId));
    };

    return (
        <div id="avb-actions-required">
            <div className="action-heading">
                <RedFlagIcon className="flag-icon" />
                <span>ACTION REQUIRED</span>
            </div>
            <div className="actions-container">
                {actions.map((action) => (
                    <Action
                        action={action}
                        actionClick={onActionClick}
                        key={action.id}
                        selected={action.id === selectedActionId}
                    />
                ))}
            </div>
        </div>
    );
};

export default ActionRequired;
