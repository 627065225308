import { Tooltip } from "antd";
import { useState } from "react";
import RedFlagIcon from "../Shared/icons/RedFlagIcon";
import HelpRequestModal from "./HelpRequestModal";

const HelpRequestBadge = ({ helpRequests, applicant = {} }) => {
    const [showRequest, setShowRequest] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState();

    if (!helpRequests || !helpRequests.length) {
        return null;
    }

    const shoeRequestModal = (request) => {
        setSelectedRequest(request);
        setShowRequest(true);
    };

    const getNewRequests = (helpRequests) => {
        let filterData = helpRequests.filter((x) => x.helpStatus === "NEW");
        return filterData.sort((a, b) => {
            const c =
                (a.modifiedDate && new Date(a.modifiedDate)) ||
                (a.createdDate && new Date(a.createdDate));
            const d =
                (b.modifiedDate && new Date(b.modifiedDate)) ||
                (b.createdDate && new Date(b.createdDate));
            return d - c;
        });
    };

    const getList = () => {
        return (
            <ul className="request-flags">
                {helpRequests &&
                    getNewRequests(helpRequests).map((r: any) => (
                        <li key={r.id} onClick={() => shoeRequestModal(r)}>
                            <RedFlagIcon className="request-flag-icon" />
                            Help Request: {r?.helpRequestTopic}
                        </li>
                    ))}
            </ul>
        );
    };

    const newRequests = getNewRequests(helpRequests);

    return newRequests && newRequests.length > 0 ? (
        <div className="avb-help-request-badge">
            <Tooltip
                placement="top"
                title={getList()}
                color={"#2a3244"}
                mouseEnterDelay={0}
            >
                <div>{newRequests.length}</div>
            </Tooltip>
            <HelpRequestModal
                show={showRequest}
                onCancel={() => setShowRequest(false)}
                request={selectedRequest}
                applicant={applicant}
            />
        </div>
    ) : null;
};

export default HelpRequestBadge;
