import { Button, Modal } from "react-bootstrap";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { resolveHelpRequest } from "../../services/HelpRequestService";
import { capitalize, formatPhone } from "../../utils/Utils";
import { useRef } from "react";

const HelpRequestModal = ({ show, onCancel, request, applicant }) => {
    const dispatch = useDispatch();
    const emailDiv = useRef(null);
    const resolveRequest = () => {
        dispatch(resolveHelpRequest(applicant, request));
        onCancel();
    };
    if (!request || !applicant) {
        return null;
    }
    const filtered_applicant =
        applicant &&
        applicant.applicants.find((a: any) => a.id === request.applicantId);
    const formatDate = (input: string) => {
        const d = new Date(input);
        let hours = d.getHours();
        hours %= 12;
        const minutes = d.getMinutes();
        const ampm = hours >= 12 ? "am" : "pm";
        let format = `${hours}:${minutes}${ampm}, ${d.toLocaleDateString()}`;
        return format; //d.toLocaleString('en-US')
    };

    const hasEllipsis = () => {
        if (emailDiv.current !== null) {
            return (
                emailDiv.current["offsetWidth"] <
                emailDiv.current["scrollWidth"]
            );
        }
        return true;
    };

    return (
        <Modal centered show={show}>
            <Modal.Header className="border-bottom">
                <Modal.Title>{applicant.unitId} - Help Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="details block-labels">
                    <div className="row">
                        <div className="col-6">
                            <label className="d-block">Topic</label>
                            {request.helpRequestTopic}
                        </div>
                        <div className="col-6">
                            <label className="d-block">Applicant Name</label>
                            {filtered_applicant &&
                                capitalize(
                                    `${filtered_applicant.firstName} ${filtered_applicant.lastName}`
                                )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label className="d-block">Date Sent</label>
                            {(request.modifiedDate &&
                                formatDate(request.modifiedDate)) ||
                                (request.createdDate &&
                                    formatDate(request.createdDate))}
                        </div>
                        <div className="col-6 text-truncate" ref={emailDiv}>
                            <label className="d-block">Email</label>
                            <span>
                                {hasEllipsis() ? (
                                    <Tooltip
                                        placement="top"
                                        title={
                                            filtered_applicant &&
                                            filtered_applicant.emailAddress
                                        }
                                        color={"#2a3244"}
                                        mouseEnterDelay={0}
                                        overlayClassName="help-request-email-tooltip"
                                    >
                                        {filtered_applicant &&
                                            filtered_applicant.emailAddress}
                                    </Tooltip>
                                ) : (
                                    filtered_applicant &&
                                    filtered_applicant.emailAddress
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6 not-truncated">
                            <label className="d-block">Phone</label>
                            {filtered_applicant &&
                                formatPhone(filtered_applicant.phoneNumber)}
                        </div>
                    </div>
                    <div className="row additional-comments">
                        <div className="col">
                            <label className="d-block">
                                Additional Comments
                            </label>
                            {request.additionalComments}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top">
                <Button className="cancel-btn" onClick={onCancel}>
                    Close
                </Button>
                <Button variant="primary" onClick={resolveRequest}>
                    Resolve
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default HelpRequestModal;
