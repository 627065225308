// import React from 'react';

const InformationIcon = ({ className }) => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M7 0a7 7 0 0 0-7 7 7 7 0 0 0 7 7 7 7 0 0 0 7-7 7 7 0 0 0-7-7zm-.041 2.522a.492.492 0 1 1 0 .984.492.492 0 0 1 0-.984zm2.15 7.617c0 .272-.22.492-.493.492H5.302a.492.492 0 1 1 0-.984h1.165V5.353h-.82a.492.492 0 1 1 0-.984H6.958c.272 0 .492.22.492.491v4.787h1.165c.272 0 .492.22.492.492z"
                fill="#1890FF"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default InformationIcon;
