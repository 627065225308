import { getConfigs } from "../configs/Config";
import {
  setApplicationStatus,
  setApplicationTermsAndConditions,
  setNotification,
  setInitialFilterOptions,
  setApplications,
  setApplication,
} from "../store/actions";
import { getHelpRequestsBulk } from "./HelpRequestService";
import { fetchAccessToken, fetchIdToken } from "../utils/Auth";
import { chunk } from "../utils/Utils";
import LocalStorageService from "./LocalStorageService";

export const getApplications = (communityIds = []) => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/applications/search`;

  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    const options = {
      method,
      headers,
      body: JSON.stringify({
        communityIds: communityIds,
      }),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (res && res.applications) {
          const applicationIds = res.applications.map((application) => {
            return application.applicationId;
          });

          const applicationIdChunks = chunk(applicationIds, 100);
          applicationIdChunks.map((group) => {
            dispatch(getHelpRequestsBulk(group));
          });
        }

        dispatch(setApplications(res));
      })
      .catch((error) => {
        console.log("error on getting applications ", error);
        dispatch(setApplications({ data: [] }));
      });
  };
};

export const getApplicationById = (applicationId) => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/applications/${applicationId}`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    headers.append("x-access-token", fetchAccessToken() || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        const { application: applicationFromLocal } =
          LocalStorageService.getApplication(applicationId);

        const application = {
          ...applicationFromLocal,
          ...res,
        };

        dispatch(setApplication(application));
        LocalStorageService.setApplicationInLocal(application, null);
      })
      .catch((error) => {
        console.log(
          `error on getting application by id - ${applicationId}`,
          error
        );
      });
  };
};

export const getFilterOptions = () => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/filter-options`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");

    const options = {
      method,
      headers,
    };

    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(setInitialFilterOptions(res));
      })
      .catch((error) => {
        console.log("error on getting initial filter options ", error);
      });
  };
};

/**
 * Helper function for converting filter selection array to string
 * Note: This also checks whether the selection is already an array
 */
const formatArrayToString = (input) => {
  if (Array.isArray(input)) {
    // extract values from the array
    const values = input.map((i) => i.value);
    // concatenate values for POST /filter-options
    return values.join(",");
  }
  return input;
};

export const updateUserFilterSelection = (selectedOptions) => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/filter-options`;

  return () => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      regions: formatArrayToString(selectedOptions.regions),
      markets: formatArrayToString(selectedOptions.markets),
      communities: formatArrayToString(selectedOptions.communities),
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then(() => {
        /**
         * No need to do anything here. Reaching this point
         * simply means that the new filter selections were saved.
         */
      })
      .catch((error) => {
        console.log("Error on getting initial filter options ", error);
      });
  };
};

export const getApplicationStatus = (applicationId, applicantId) => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/payments/${applicationId}/applicant/${applicantId}`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(setApplicationStatus(res));
      })
      .catch((error) => {
        console.log("Error on getting application status ", error);
      });
  };
};

export const getApplicationTermsAndConditions = (applicantId) => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/terms-and-conditions/${applicantId}`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(setApplicationTermsAndConditions(res));
      })
      .catch((error) => {
        console.log("Error on getting application documents ", error);
      });
  };
};

export const cancelApplication = (applicationId, unitId) => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/cancel-application`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(Error);
        }
        dispatch(
          setNotification({
            type: "INFO",
            message: `Application for Unit ${unitId} has been successfully cancelled`,
            origin: "cancelApplication",
          })
        );
      })
      .catch((error) => {
        console.log("Error cancelling ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to cancel Application",
            message:
              "There was an error cancelling the application. Please try again.",
            origin: "cancelApplication",
          })
        );
      });
  };
};

//TODO: Adjust API route and authentication
export const leaseAgreement = (applicationId) => {
  const { apiKey } = getConfigs();
  const route = `/v2/Application/${applicationId}/LeaseAgreement`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(Error);
        }
        dispatch(
          setNotification({
            type: "INFO",
            message: `Lease for Application ${applicationId} has been generated and succesfully sent out`,
          })
        );
        dispatch(getApplicationById(applicationId));
      })
      .catch((error) => {
        console.log("Error generating lease ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to generate lease",
            message: "There was an error generating lease.\nPlease try again.",
          })
        );
      });
  };
};

export const completeAndRemoveApplication = (applicationId, unitId) => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/complete-and-remove`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };

    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (!res.error) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Application for Unit ${unitId} has been successfully finished`,
            })
          );
        } else {
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to complete Application",
              message:
                "There was an error completing the application.\nPlease try again.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error on completing application ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to complete Application",
            message:
              "There was an error completing the application.\nPlease try again.",
          })
        );
      });
  };
};

export const submitCreditScreening = (
  applicationId,
  applicantId,
  applicantName
) => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/run-fadv-screening`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
      applicantId: applicantId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (!res.errors && !res.error) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Credit Screening for ${applicantName} is in progress`,
            })
          );
        } else {
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to run credit screening",
              message:
                "Please click view/edit application, check each applicant’s entered address and make any necessary edits, then click submit again.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error credit screening ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to run credit screening",
            message:
              "Please click view/edit application, check each applicant’s entered address and make any necessary edits, then click submit again.",
          })
        );
      });
  };
};

export const confirmDeposit = (applicationId, applicantName) => {
  const { apiKey, baseRoute } = getConfigs();
  const route = `${baseRoute}/application/{applicationId}/confirm-deposit-paid`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", sessionStorage.getItem("token") || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      applicationId: applicationId,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (!res.errors && !res.error) {
          dispatch(
            setNotification({
              type: "INFO",
              message: `Condition Met, increased security deposit met for ${
                applicantName || "applicant"
              }, Application ${applicationId}`,
            })
          );
        } else {
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to confirm increased deposit paid",
              message:
                "There was an error confirming increased deposit paid.\nPlease try again.",
            })
          );
        }
      })
      .catch((error) => {
        console.log("Error confirming ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to confirm increased deposit paid",
            message:
              "There was an error confirming increased deposit paid.\nPlease try again.",
          })
        );
      });
  };
};

export const updateApplicantById = (applicantId, applicantData) => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/applicants/${applicantId}`;

  return (dispatch) => {
    const method = "PUT";
    const headers = new Headers({
      "Content-type": "application/json",
    });
    headers.append("Accept", "application/json");
    headers.append("Authorization", fetchIdToken() || "");
    headers.append("x-api-key", apiKey || "");
    const body = {
      firstName: applicantData.firstName,
      middleName: applicantData.middleName,
      lastName: applicantData.lastName,
      dob: applicantData.dob,
      address1: applicantData.address1,
      address2: applicantData.address2,
      city: applicantData.city,
      state: applicantData.state,
      zip: applicantData.zip,
      emailAddress: applicantData.emailAddress,
      nonUSPhone: applicantData.nonUSPhone,
      isSSN: applicantData.isSSN,
      sMSPreference: applicantData.sMSPreference,
      emailPreference: applicantData.emailPreference,
      isMiddleName: applicantData.isMiddleName,
      phoneNumberType: applicantData.phoneNumberType,
      phoneNumber: applicantData.phoneNumber,
    };
    const options = {
      method,
      headers,
      body: JSON.stringify(body),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (res.status === 400) {
          dispatch(
            setNotification({
              type: "VALIDATION_ERROR",
              title: "Update Applicant Info",
              message: JSON.stringify(res.errors),
            })
          );
          return;
        }

        dispatch(
          setNotification({
            type: "INFO",
            title: "Update Applicant Info",
            message: `Applicant Info for applicant ${applicantId} has been successfully updated`,
          })
        );
        return res;
      })
      .catch((error) => {
        console.log(
          `Error on updating applicant info for applicant - ${applicantId}`,
          error
        );
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Update Applicant Info",
            message: "Unable to update applicant information",
          })
        );
      });
  };
};
