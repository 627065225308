// NATIVE IMPORTS
require("./Applicants.scss");
import { useDispatch } from "react-redux";
import { Collapse, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Dropdown } from "react-bootstrap";

// SERVICES
import LocalStorageService from "../../services/LocalStorageService";
import { getNotificationMessageForApplicant } from "../../services/NotificationFormattingService";
import {
    isApplicationStatusExpired,
    getRemainingTimeInMs,
    isClockExpired,
} from "../../utils/HoldClock";

// CUSTOM COMPONENTS
import HoldClockStatus from "../Shared/HoldClockStatus";
import ApplicantInformationModal from "./ApplicantInformationModal";
import {
    getApplicationStatus,
    getApplicationTermsAndConditions,
} from "../../services/AdminDashService";

import {
    formatPhone,
    getStatusName,
    getGenderEquivalent,
    isApplicationEditable,
} from "../../utils/Utils";
import CancelApplicationModal from "./CancelApplicationModal";
import { ApplicationStatusText } from "../../types/Application";
import { ApplicantType } from "../../types/Applicant";

const Applicants = ({ application }) => {
    const { id } = useParams();
    const [applicantData, setApplicantData]: any = useState();
    const [guarantorsList, setGuarantorsList]: any = useState([]);
    const [dependentsList, setDependentsList]: any = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalApplicant, setModalApplicant] = useState({});
    const [columns, setColumns] = useState([]);
    const [selectedApplication, setSelectedApplication]: any = useState();
    const [selectedApplicant, setSelectedApplicant]: any = useState();

    const { Panel } = Collapse;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!application) return;
        setSelectedApplication(application);
        prepareData(application.applicants);

        setDependentsList(formatData(application.dependents));
    }, [application]);

    /*
     * Defines the Custom Toggle for the React Dropdown Component
     * See Reference:
     * - https://react-bootstrap.github.io/components/dropdowns/
     * - https://stackoverflow.com/questions/61433318/explanation-for-this-complicated-is-not-assignable-to-type-error-in-typescript
     */

    /* eslint-disable */
    const CustomToggle = React.forwardRef<HTMLDivElement>((props, ref) => (
        <div ref={ref} {...props}>
            <span className="meatball-menu">...</span>
        </div>
    ));
    /* eslint-enable */

    const getColumns = (title: string, itemCount: number) => [
        {
            title: `${title} (${itemCount})`,
            dataIndex: "fullName",
            width: 180,
            className: "avb-fullname-col",
        },
        {
            title: title == "Adults" ? "Status" : "",
            dataIndex: "status",
            width: 180,
            className: "avb-status-col",
            render: (status) => {
                const { applicationHold } = application;
                const isHoldClock = Boolean(
                    applicationHold && applicationHold?.holdDate
                );
                let remainingTime;
                if (isHoldClock && status !== "Expired") {
                    const holdDateFormatted = new Date(
                        applicationHold.holdDate
                    );
                    remainingTime = getRemainingTimeInMs(holdDateFormatted);
                    if (
                        status == "In Progress" &&
                        isClockExpired(remainingTime)
                    ) {
                        status = "Expired";
                    }
                }

                return (
                    <HoldClockStatus
                        status={status}
                        remainingTime={remainingTime}
                    />
                );
            },
        },
        {
            title: title == "Adults" ? "Email" : "",
            dataIndex: "emailAddress",
            width: 180,
            className: "avb-email-col",
            render: (email) => <span title={email}>{email}</span>,
        },
        {
            title: title == "Adults" ? "Phone" : "",
            dataIndex: "phoneNumber",
            width: 140,
            className: "avb-phone-col",
            render: (phone) => formatPhone(phone),
        },
    ];

    const prepColumns = (itemCount: number) => {
        const applicationData = application ?? {};
        const showSendNotificationAction =
            applicationData.applicationStatus !==
                ApplicationStatusText.LEASE_SIGNED &&
            applicationData.applicationStatus !==
                ApplicationStatusText.LEASE_PENDING;
        const col: any = getColumns("Adults", itemCount);

        col.push({
            title: "",
            width: 10,
            className: "avb-action-col",
            render: (text, record) => {
                const isEditable = isApplicationEditable(record);
                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            className="avb-context"
                        >
                            ...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {showSendNotificationAction && (
                                <Dropdown.Item
                                    eventKey="2"
                                    onClick={() =>
                                        onSendNotificationClick(record)
                                    }
                                >
                                    Send Notification
                                </Dropdown.Item>
                            )}
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => onViewApplicationClick(record)}
                            >
                                {isEditable ? "View / Edit" : "View"}{" "}
                                Application
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                );
            },
        });

        setColumns(col);
    };

    const getGuarantorColumns = (title: string, itemCount: number) => {
        const col: any = getColumns(title, itemCount);

        if (title === "Dependents") return col;

        col.push({
            title: "",
            width: 10,
            className: "avb-action-col",
            render: (text, record) => {
                const isEditable =
                    record.emailAddress &&
                    (record.nonUSPhone == true ||
                        (record.phoneNumber && record.phoneNumberType));

                return (
                    <Dropdown>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            className="avb-context"
                        >
                            ...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="2"
                                onClick={() => onSendNotificationClick(record)}
                            >
                                Send Notification
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="1"
                                onClick={() => onViewApplicationClick(record)}
                            >
                                {isEditable ? "View / Edit" : "View"}{" "}
                                Application
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                );
            },
        });

        return col;
    };

    const prepareData = (applicants: any) => {
        const applicantsArray: any = [];
        const guarantorsArray: any = [];
        const len = applicants.length;
        for (let i = 0; i < len; i++) {
            const tData = applicants[i];
            const statusId = tData.applicationProcess
                ? tData.applicationProcess.applicationStatusId
                : null;

            var statusName = getStatusName(statusId);
            if (isApplicationStatusExpired(statusName, application)) {
                statusName = "Expired";
            }

            const data = {
                id: tData.id,
                fullName:
                    (tData.firstName || "") + " " + (tData.lastName || ""),
                status: statusName,
                emailAddress: tData.emailAddress,
                phoneNumber: tData.phoneNumber,
                lastLoggedIn: "",
                key: `${tData.authenticationId}-${i}`,
                firstName: tData.firstName,
                middleName: tData.middleName,
                lastName: tData.lastName,
                suffix: tData.suffix,
                phoneNumberType: tData.phoneNumberType,
                dob: tData.dob,
                ssn: tData.ssn,
                address1: tData.address1,
                address2: tData.address2,
                city: tData.city,
                state: tData.state,
                zip: tData.zip,
                driverLicense: tData.stateLicenseNumber,
                licenseIssuer: tData.stateLicenseIssuer,
                annualIncome: tData.annualIncome,
                applicationId: application.applicationId,
                employer:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].employer,
                industry:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].industry,
                employerPhone:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].employerPhone,
                employerAddress1:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].address1,
                employerAddress2:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].address2,
                employerCity:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].city,
                employerState:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].state,
                employerZip:
                    tData.applicantEmployer &&
                    tData.applicantEmployer[0] &&
                    tData.applicantEmployer[0].zip,
                gender: getGenderEquivalent(tData.gender),
                householdStatus: tData.householdStatus,
                nonUSPhone: tData.nonUSPhone,
                isSSN: tData.isSSN,
                smsPreference: tData.smsPreference,
                emailPreference: tData.emailPreference,
                isMiddleName: tData.isMiddleName,
            };
            if (!applicants[i].applicantTypeId) {
                // ignore applicant w/o applicantTypeId
                continue;
            } else if (
                applicants[i].applicantTypeId == ApplicantType.Applicant ||
                applicants[i].applicantTypeId == ApplicantType.CoTenant
            ) {
                applicantsArray.push(data);
            } else {
                guarantorsArray.push(data);
            }
        }

        setApplicantData(applicantsArray);
        setGuarantorsList(guarantorsArray);

        prepColumns(applicantsArray.length);
    };

    const formatData = (userList: any) => {
        const formattedData: any = [];
        userList.map((user: any) => {
            const statusId = user.applicationProcess
                ? user.applicationProcess.applicationStatusId
                : null;
            const data = {
                fullName: (user.firstName || "") + " " + (user.lastName || ""),
                status: getStatusName(statusId),
                emailAddress: user.emailAddress,
                phoneNumber: user.phoneNumber,
                lastLoggedIn: "",
            };
            formattedData.push(data);
        });
        return formattedData;
    };

    const onViewApplicationClick = (record) => {
        dispatch(getApplicationStatus(id, record.id));
        dispatch(getApplicationTermsAndConditions(record.id));
        setModalApplicant(record);
        setModalShow(true);
    };

    const onSendNotificationClick = (record) => {
        const application =
            selectedApplication ??
            LocalStorageService.getApplication(id).application;

        const notificationMessage = getNotificationMessageForApplicant(
            application,
            record
        );

        window.open(
            `mailto:${record.emailAddress}?subject=${
                notificationMessage.subject
            }&body=${encodeURI(notificationMessage.body ?? "")}`
        );
    };

    return (
        <div id="avb-applicants">
            <ApplicantInformationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                applicant={modalApplicant}
            />
            <Collapse defaultActiveKey={"appinfo"} bordered={false}>
                <Panel header={`APPLICANT INFORMATION`} key="appinfo">
                    <Table
                        dataSource={applicantData}
                        columns={columns}
                        pagination={false}
                        rowKey={(record) => `${record.id}-${record.status}`}
                    />
                    <Table
                        dataSource={[...guarantorsList]}
                        columns={getGuarantorColumns(
                            "Guarantors",
                            guarantorsList.length
                        )}
                        pagination={false}
                        rowKey={(record) => `${record.id}-${record.status}`}
                    />
                    <Table
                        dataSource={dependentsList}
                        columns={getGuarantorColumns(
                            "Dependents",
                            dependentsList.length
                        )}
                        pagination={false}
                    />
                </Panel>
            </Collapse>
            <CancelApplicationModal
                selectedApplicant={selectedApplicant}
                selectedApplication={selectedApplication}
                onCancel={() => setSelectedApplicant(undefined)}
                type="cancel-application"
            />
        </div>
    );
};

export default Applicants;
