export const setApplications = (data) => {
  return {
    type: "SET_APPLICATIONS",
    data,
  };
};

export const getApplications = () => {
  return {
    type: "GET_APPLICATIONS",
  };
};

export const setApplication = (data) => {
  return {
    type: "SET_APPLICATION",
    data,
  };
};

export const getApplication = () => {
  return {
    type: "GET_APPLICATION",
  };
};

export const setInitialFilterOptions = (data) => {
  return {
    type: "SET_INITIAL_FILTER_OPTIONS",
    data,
  };
};

export const setFilterOptions = (data) => {
  return {
    type: "SET_FILTER_OPTIONS",
    data,
  };
};

export const getFilterOptions = () => {
  return {
    type: "GET_FILTER_OPTIONS",
  };
};
export const setActionRequiredId = (data) => {
  return {
    type: "SET_ACTION_REQUIRED_ID",
    data,
  };
};

export const setSearchTerms = (data) => {
  return {
    type: "SET_SEARCH_TERMS",
    data,
  };
};

export const setHelpRequests = (data) => {
  return {
    type: "SET_HELP_REQUESTS",
    data,
  };
};

export const resetHelpRequests = () => {
  return {
    type: "RESET_HELP_REQUESTS",
  };
};

export const removeHelpRequest = (data) => {
  return {
    type: "REMOVE_HELP_REQUEST",
    data,
  };
};

export const setApplicationStatus = (payload) => {
  return {
    type: "SET_APPLICATION_STATUS",
    payload,
  };
};

export const setApplicationTermsAndConditions = (payload) => {
  return {
    type: "SET_APPLICATION_TERMS_AND_CONDITIONS",
    payload,
  };
};

export const setNotification = (payload) => {
  return {
    type: "SET_NOTIFICATION",
    payload,
  };
};

export const resetNotification = () => {
  return {
    type: "RESET_NOTIFICATION",
  };
};

export const getApplicationHoldFromNow = (data) => {
  return {
    type: "GET_APPLICATION_HOLD_FROM_NOW",
    data,
  };
};
