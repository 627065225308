import { Button, Modal } from "react-bootstrap";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { resetNotification } from "../../store/actions";

const WarningModal = () => {
    const notification = useSelector(
        (state: RootStateOrAny) => state.notification
    );
    const dispatch = useDispatch();
    const reset = () => dispatch(resetNotification());
    return (
        <Modal
            centered
            show={notification && notification.type === "ERROR"}
            className="warning-modal"
        >
            <Modal.Body>
                <div className="error-message">
                    {notification && (
                        <div>
                            <h2>{notification.title}</h2>
                            <p className="display-line-break">
                                {notification.message}
                            </p>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    className="btn-blue-grey"
                    onClick={reset}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default WarningModal;
