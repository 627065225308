import { Applicant, ApplicantType } from "./Applicant";

export interface MonthlyCharge {
  label: string;
  amount: number;
  header: string;
}

// Type coming from GET /Applications/{applicationId} API
export interface Application {
  readonly applicationId: number;
  unitId: string;
  unit: number | null;
  primaryApplicantFirstName: string;
  primaryApplicantLastName: string;
  applicationStatus: ApplicationStatusText;
  applicationStatusId: ApplicationStatusId;
  identityScreeningId: number;
  creditScreeningOutcome: null;
  communityId: string;
  phaseId: string;
  buildingId: string;
  communityName: string;
  createdDate: string;
  modifiedDate: string;
  moveInDate: string;
  leaseGeneratedDate: string;
  leaseTerm: number;
  applicationScreeningResult: string;
  applicationScreeningResultId: number;
  applicants: Applicant[];
  dependents: any[];
  monthlyCharges: MonthlyCharge[];
  applicationHold: ApplicationHold;
  enableApplicationHold: boolean;
  enableDocusign: boolean;
  parking?: Parking | null;
  lease: Lease;
}

// Type after data has been processed by redux reducers
export interface ReduxApplication extends Application {
  readyToFinishCount?: number;
  completeUnitId?: string;
}

export interface LeaseEvent {
  id: number;
  createdDate: string;
  expirationDate: string;
  applicationId: number;
  leaseEventId: number;
  refId: string;
  esignStatus: string;
  dsDocPath: string;
}

export interface PostLeaseResponse {
  expirationDate: string; // "2023-11-10T20:31:38.078Z"
  applicationId: number;
  leaseEventId: number;
  esignStatus: string;
  dsDocPath: string; // url for the docusign document
}

export interface Lease {
  canGenerateLease: boolean;
  canResendLease: boolean;
  leaseEvents: LeaseEvent[];
}

export interface Parking {
  optInApplicantId?: number | null; // returned with reservation
  optOutApplicantId?: number | null; // returned without reservation
  reservationUrl?: string | null; // returned with reservation
}

export interface ApplicationHold {
  id: number;
  applicationId: number;
  holdDate: string;
  updateMins: number;
  updateEventId: 1;
  updateEvent: string;
  updateOperationId: 2;
  updateOperation: string;
  createdDate: string;
  associateId: any | null;
}

export interface ApplicationProcess {
  id: number;
  applicationId: number;
  applicationStatusId: ApplicationStatusId;
  applicationStageId: ApplicationStage;
  parentApplicantId: number;
  applicantTypeId: ApplicantType;
  createdDate: string;
  modifiedDate: string;
}

export enum ApplicationStage {
  ApplicantInformation = 1,
  OccupantsAndGuarantors,
  TermsAndCondition,
  Review,
  PayAndSubmit,
  TwoStepScreening,
  SignLease,
}

export enum ApplicationStatusText {
  IN_PROGRESS = "In Progress",
  REJECTED = "Rejected",
  COMPLETED = "Completed",
  REMOVED = "Removed",
  CREDIT_AND_ID_RETURNED = "Credit And ID Returned",
  LEASE_PENDING = "Lease Pending",
  LEASE_SIGNED = "Lease Signed",
  CREADIT_AND_ID_PENDING = "Credit And ID Pending",
  CANCELLED = "Cancelled",
  APPROVED = "Approved",
  CLOSED = "Closed",
  INVITE_SENT = "Invite Sent",
  ACTION_REQUIRED = "Action Required",
  APPLICATION_COMPLETE = "Application Complete",
  LEASE_READY = "Lease Ready",
  LEASE_DECLINED = "Lease Declined",
  LEASE_EXPIRED = "Lease Expired",
}

export enum ApplicationStatusId {
  InviteSent = 0,
  InProgress,
  Rejected,
  Completed,
  Removed,
  CreditAndIDReturned,
  LeasePending,
  LeaseSigned,
  CreditAndIDPending,
  Cancelled,
  Approved,
  Closed,
  ActionRequired,
  ApplicationComplete,
  LeaseReady,
  LeaseExpired,
  LeaseDeclined,
}

export enum LeaseEventId {
  LeaseExpired = 1,
  LeaseDeclined,
  LeaseGenerated,
  LeaseIDSet,
  LeaseReady,
  LeaseSigned,
  EsignEvent,
  LeaseResent,
}
