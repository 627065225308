import { useEffect, useState } from "react";
import { Tag } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
    getRemainingTimeInMs,
    getHoldClockColor,
    getTimeColor,
    getTimeParts,
    isClockExpired,
    isApplicationScreeningResultValid,
} from "../../utils/HoldClock";

const startClock = (application) => {
    const { applicationHold, applicationScreeningResult } = application;

    if (
        isApplicationScreeningResultValid(applicationScreeningResult) == false
    ) {
        return false;
    }

    if (!applicationHold || !applicationHold.holdDate) {
        return false;
    }

    const holdDateFormatted = new Date(applicationHold.holdDate);
    const remainingTime = getRemainingTimeInMs(holdDateFormatted);
    return !isClockExpired(remainingTime);
};

const formatHoldClock = (holdDate, type) => {
    const holdDateFormatted = new Date(holdDate);
    const remainingTime = getRemainingTimeInMs(holdDateFormatted);
    const [hours, minutes, seconds] = getTimeParts(remainingTime);
    const color = getHoldClockColor(remainingTime);
    const timeColor = getTimeColor(remainingTime);
    const clockExpired = isClockExpired(remainingTime);

    return (
        <div className={`hold-clock-detail-container ${type}`}>
            <span>
                <Tag
                    className={`hold-clock-color-${color} time-color-${timeColor} ${
                        clockExpired == true ? "clock-expired" : ""
                    }`}
                    icon={<ClockCircleOutlined />}
                >
                    <span className="clock">
                        {hours}:{minutes}:{seconds}
                    </span>
                </Tag>
            </span>
        </div>
    );
};

const getHoldClock = (application, type) => {
    const { applicationHold, applicationScreeningResult } = application;

    if (
        isApplicationScreeningResultValid(applicationScreeningResult) == false
    ) {
        return <></>;
    }

    if (applicationHold && applicationHold.holdDate) {
        return formatHoldClock(applicationHold.holdDate, type);
    }

    return <></>;
};

const HoldClock = ({ application, type }) => {
    const [clock, setClock] = useState(getHoldClock(application, type));

    useEffect(() => {
        const interval = setInterval(() => {
            if (startClock(application) == true) {
                setClock(getHoldClock(application, type));
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [application]);

    return clock;
};
export default HoldClock;
