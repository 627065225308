import { Collapse, Table } from "antd";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { convertToLocalAndFormatDate, formatTime } from "../../utils/Utils";
import SubmitAgainIcon from "../Shared/icons/SubmitAgainIcon";
import DollarIcon from "../Shared/icons/DollarIcon";
import SubmitCreditScreeningModal from "./SubmitCreditScreeningModal";
import ConfirmIncreasedDepositModal from "./ConfirmIncreasedDepositModal";
import StatusTag from "../Shared/StatusTag";
import { ApplicantType } from "../../types/Applicant";
import { ApplicationStage, ApplicationStatusId } from "../../types/Application";

/**
 * Helper function for sorting dates w/ consideration
 *      over the createdOn and modifiedDate
 */
const sortByDateDesc = (a, b) => {
  const dateA = new Date(a.modifiedDate || a.createdOn);
  const dateB = new Date(b.modifiedDate || b.createdOn);

  if (dateA.getTime() > dateB.getTime()) {
    return -1;
  }

  if (dateA.getTime() < dateB.getTime()) {
    return 1;
  }

  return 0;
};

/**
 * Helper function for exposing the onfido and fadv screening status,
 *  as well as the most recent update dated.
 */
const getScreeningData = (applicantScreening, setLatestDate) => {
  const hasApplicantScreeningData =
    applicantScreening && applicantScreening.length;

  const statuses = {};
  let latestDate = null;

  if (hasApplicantScreeningData) {
    // sort descending by dates
    applicantScreening.sort(sortByDateDesc);

    // since this is sorted in descending order
    // the most updated dates should be available first
    applicantScreening.sort(sortByDateDesc).forEach((screening, idx) => {
      const {
        screeningVendor,
        screeningStatus,
        createdOn,
        modifiedDate,
        isSoftDeleted,
      } = screening;

      const isComplete = !!statuses["onfido"] && !!statuses["fadv"];

      // if no vendor or already complete, end fast
      if (!screeningVendor || isComplete || isSoftDeleted) return;

      // capture latest date
      if (idx === 0) {
        latestDate = modifiedDate || createdOn;
      }

      // format
      const vendor = screeningVendor.toLowerCase();
      statuses[vendor] = {
        lastUpdated: modifiedDate || createdOn,
        screeningStatus,
        screeningVendor,
      };
    });

    statuses["latestDate"] = latestDate;
    if (latestDate) {
      const formattedDate = `${formatTime(
        latestDate
      )}, ${convertToLocalAndFormatDate(latestDate)}`;
      setLatestDate(formattedDate);
    }
    return statuses;
  }
};

const Applicants = ({ _application }) => {
  const [applicantData, setApplicantData] = useState([]);
  const [guarantorData, setGuarantorData] = useState([]);
  const notification = useSelector(
    (state: RootStateOrAny) => state.notification
  );
  const [application, setApplication] = useState({
    applicants: [
      {
        applicantScreening: [
          {
            screeningVendor: "",
          },
        ],
        applicationProcess: {
          applicationStageId: 0,
          applicationStatusId: 0,
        },
        applicantTypeId: 0,
      },
    ],
    applicationScreeningResult: "",
    applicationScreeningResultId: 0,
    createdDate: "",
    applicationId: 0,
  });
  const [applicantColumns, setApplicantColumns] = useState([]);
  const [guarantorColumns, setGuarantorColumns] = useState([]);
  const { Panel } = Collapse;
  const [latestDate, setLatestDate] = useState("");
  const [showCreditScreeningModal, setShowCreditScreeningModal] =
    useState(false);
  const [
    showConfirmIncreasedDepositModal,
    setShowConfirmIncreasedDepositModal,
  ] = useState(false);

  useEffect(() => {
    _application && prepareApplicantData(_application);
  }, [_application]);

  const prepareApplicantData = (app) => {
    app.applicants.forEach((applicant) => {
      const { applicantScreening } = applicant;
      const screeningData = getScreeningData(applicantScreening, setLatestDate);
      let onfidoStatus = null;
      let fadvStatus = null;

      if (!screeningData) return;

      if (!!screeningData["onfido"]) {
        onfidoStatus = screeningData["onfido"].screeningStatus;
      }

      if (!!screeningData["fadv"]) {
        fadvStatus = screeningData["fadv"].screeningStatus;
      }

      applicant.onfidoStatus = onfidoStatus || ""; // action - credit screening outcome
      applicant.fadvStatus = fadvStatus || ""; // status - id screening outcome
      applicant.lastUpdated = screeningData["latestDate"]
        ? screeningData["latestDate"]
        : "";
    });

    setApplication(app);
    prepareData(app.applicants);
  };

  const prepColumns = (title: string, itemCount: number) => {
    const col: any = [
      {
        title: `${title} (${itemCount})`,
        dataIndex: "fullName",
        key: "fullName",
        width: "25%",
      },
      {
        title: "Credit Screening Status",
        dataIndex: "credit",
        key: "credit",
      },
      {
        title: "ID Fraud Status",
        dataIndex: "identity",
        key: "identity",
      },
      {
        title: "Last Update",
        dataIndex: "modifiedDate",
        key: "modifiedDate",
      },
    ];

    if (title === "Adults") setApplicantColumns(col);
    else if (title === "Guarantors") setGuarantorColumns(col);
  };

  const prepareData = (applicants: any) => {
    const arr: any = [];
    const guarantors: any = [];
    const len = applicants.length;
    for (let i = 0; i < len; i++) {
      const tData = applicants[i];
      // some applicationProcess is null or undefined
      const formattedDate = tData.lastUpdated
        ? `${formatTime(tData.lastUpdated)}, ${convertToLocalAndFormatDate(
            tData.lastUpdated
          )}`
        : "";
      const data = {
        fullName: (tData.firstName || "") + " " + (tData.lastName || ""),
        credit: tData.fadvStatus ?? "",
        identity: (tData.onfidoStatus ?? "Pending").replace(
          "Pending",
          "Not Started"
        ),
        modifiedDate: formattedDate,
        key: `${tData.authenticationId}-${i}`,
      };
      if (
        applicants[i].applicantTypeId === ApplicantType.Applicant ||
        applicants[i].applicantTypeId === ApplicantType.CoTenant
      ) {
        arr.push(data);
      } else {
        guarantors.push(data);
      }
    }
    setApplicantData(arr);
    setGuarantorData(guarantors);
    prepColumns("Adults", arr.length);
    prepColumns("Guarantors", guarantors.length);
  };

  const showSubmitAgain = () => {
    const { applicants } = application || {};

    if (!applicants) {
      return false;
    }

    const everyoneInTwoStepScreening = applicants.every(
      ({ applicationProcess }) =>
        applicationProcess &&
        applicationProcess.applicationStageId ===
          ApplicationStage.TwoStepScreening
    );

    const hasNoFADV = applicants.every(({ applicantScreening }) => {
      if (applicantScreening !== null && applicantScreening.length > 0) {
        return applicantScreening.every((s) => {
          return s.screeningVendor !== "FADV";
        });
      }
      return true;
    });

    const hasGuarantorWithCompleteStatus = applicants.some(
      ({ applicantTypeId, applicationProcess }) =>
        applicantTypeId === ApplicantType.Guarantor &&
        applicationProcess?.applicationStatusId ===
          ApplicationStatusId.ApplicationComplete
    );

    const allApplicantsAndCoTenantsHaveCreditAndIDReturned = applicants
      .filter(
        ({ applicantTypeId }) =>
          applicantTypeId === ApplicantType.Applicant ||
          applicantTypeId === ApplicantType.CoTenant
      )
      .every(
        ({ applicationProcess }) =>
          applicationProcess &&
          applicationProcess.applicationStatusId ===
            ApplicationStatusId.CreditAndIDReturned
      );

    return (
      everyoneInTwoStepScreening &&
      (hasNoFADV ||
        (hasGuarantorWithCompleteStatus &&
          allApplicantsAndCoTenantsHaveCreditAndIDReturned))
    );
  };

  const getPrimaryApplicant = () => {
    if (!application) return null;
    return application.applicants.find((x) => x.applicantTypeId === 1);
  };

  const hasFadvRequestSuccess =
    !!notification &&
    notification.type === "INFO" &&
    notification.message.includes("Credit Screening for");

  const hasCreditScreeningSuccess =
    !!notification &&
    notification.type === "INFO" &&
    notification.message.includes(
      "Condition Met, increased security deposit met for"
    );

  const showConfirmIncreasedDepositButton =
    application &&
    application.applicationScreeningResult &&
    application.applicationScreeningResult.toUpperCase() ===
      "APPROVED WITH CONDITIONS" &&
    !hasCreditScreeningSuccess;

  /**
   * NOTE: Expected values for overAllScreeningStatuses
   *  are "Pending", "Declined" and "Accepted"
   */
  return (
    <div id="avb-screening">
      <Collapse defaultActiveKey={"1"} bordered={false}>
        <Panel header={`SCREENING & ID VERIFICATION OUTCOMES`} key="1">
          <div className="row">
            <div className="blue-card col-sm-12 col-md-4">
              <h3>Group Screening Outcome</h3>
              <p>GROUP DECISION</p>
              {application && application.applicationScreeningResult && (
                <p className="tagPlaceHolder">
                  <StatusTag name={application.applicationScreeningResult} />
                </p>
              )}
              <p>
                TIME RECEIVED
                <span>{latestDate}</span>
              </p>
              {!hasFadvRequestSuccess && showSubmitAgain() && (
                <button
                  className="submit-again"
                  onClick={() => setShowCreditScreeningModal(true)}
                >
                  <SubmitAgainIcon /> Submit again
                </button>
              )}
              {showConfirmIncreasedDepositButton && (
                <button
                  className="increase-deposit"
                  onClick={() => setShowConfirmIncreasedDepositModal(true)}
                >
                  <DollarIcon /> Confirm Increased Deposit Paid
                </button>
              )}
            </div>
            <div className=" col-sm-12 col-md-8">
              <Table
                dataSource={applicantData}
                columns={applicantColumns}
                pagination={false}
              />
              <Table
                dataSource={guarantorData}
                columns={guarantorColumns}
                pagination={false}
              />
            </div>
          </div>
        </Panel>
      </Collapse>
      <SubmitCreditScreeningModal
        applicationId={application && application.applicationId}
        applicant={getPrimaryApplicant()}
        showModal={showCreditScreeningModal}
        setShowModal={setShowCreditScreeningModal}
      />
      <ConfirmIncreasedDepositModal
        application={application}
        showModal={showConfirmIncreasedDepositModal}
        setShowModal={setShowConfirmIncreasedDepositModal}
      />
    </div>
  );
};

export default Applicants;
