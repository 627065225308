import { getConfigs } from "../configs/Config";
import { fetchIdToken } from "../utils/Auth";
import { getApplicationHoldFromNow, setNotification } from "../store/actions";

export const getApplicationHoldDateFromNow = (applicationId, minutes) => {
  const { applicationHoldBaseRoute, applicationHoldApiKey } = getConfigs();

  const route = `${applicationHoldBaseRoute}/${applicationId}/application/holddate/fromnow?minutes=${minutes}`;
  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      "x-id-token": fetchIdToken()?.replace("Bearer ", "") || "",
      "x-api-key": applicationHoldApiKey || "",
    });
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(getApplicationHoldFromNow(res));
      })
      .catch((error) => {
        console.log("error on getApplicationHoldDateFromNow ", error);
        dispatch(getApplicationHoldFromNow(null));
      });
  };
};

export const postApplicationHoldDateFromNow = (applicationId, minutes) => {
  const { applicationHoldBaseRoute, applicationHoldApiKey } = getConfigs();

  const route = `${applicationHoldBaseRoute}/${applicationId}/application/holddate/fromnow?minutes=${minutes}`;

  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      "x-id-token": fetchIdToken()?.replace("Bearer ", "") || "",
      "x-api-key": applicationHoldApiKey || "",
    });
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then(() => {
        dispatch(
          setNotification({
            type: "INFO",
            message: `Hold Clock for Application ${applicationId} has been successfully reset`,
          })
        );
      })
      .catch((error) => {
        console.log("error on postApplicationHoldDateFromNow ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: `Unable to reset Hold Clock`,
            message: `There was an error reseting Hold Clock for Application ${applicationId}.\nPlease try again.`,
          })
        );
      });
  };
};
