import React from "react";

const SearchIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m15.17 14.372-3.215-3.212A6 6 0 1 0 1.333 7.333a6 6 0 0 0 9.827 4.622l3.214 3.214a.563.563 0 0 0 .797 0 .567.567 0 0 0-.002-.797zm-5.94-2.546c-.6.254-1.237.382-1.897.382a4.864 4.864 0 0 1-3.447-1.428 4.864 4.864 0 0 1-1.045-5.344 4.864 4.864 0 0 1 1.045-1.55A4.864 4.864 0 0 1 9.23 2.841c.58.245 1.102.597 1.55 1.045a4.864 4.864 0 0 1 1.046 5.344 4.864 4.864 0 0 1-1.046 1.55c-.448.449-.969.8-1.55 1.046z"
                fill="#72767F"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default SearchIcon;
