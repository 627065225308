const CreditReturnedIcon = () => {
    return (
        <span role="img" className="anticon">
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.993 1.857 4.163 6.7l-.91-1.593a1.469 1.469 0 1 0-2.55 1.458L2.569 9.83a1.47 1.47 0 0 0 1.077.727l.041.006.101.007h.056c.388 0 .762-.153 1.038-.43l6.19-6.208c.55-.553.57-1.43.062-2.008l-.066-.07a1.47 1.47 0 0 0-2.075.003z"
                    fill="#1D2F5C"
                    fillRule="evenodd"
                />
            </svg>
        </span>
    );
};
export default CreditReturnedIcon;
