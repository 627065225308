import { getConfigs } from "../configs/Config";
import {
  setHelpRequests,
  setNotification,
  removeHelpRequest,
} from "../store/actions";
import { fetchIdToken } from "../utils/Auth";

export const getHelpRequests = (applicationId) => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/help-requests/${applicationId}`;

  return (dispatch) => {
    const method = "GET";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: fetchIdToken() || "",
      "x-api-key": apiKey || "",
    });
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(
          setHelpRequests({
            applicationId: applicationId,
            helpRequestList: res.helpRequests.map((x) => {
              // Convert IDs to integer
              // Note: A fix in the backend will be provided as well
              const helpRequest = {
                ...x,
                applicantId: parseInt(x.applicantId),
                applicationId: parseInt(x.applicationId),
                id: parseInt(x.id),
              };
              return helpRequest;
            }),
          })
        );
      })
      .catch((error) => {
        console.log("error on getting Help Requests ", error);
      });
  };
};

export const getHelpRequestsBulk = (applicationIds) => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/help-requests/bulk-fetch`;
  return (dispatch) => {
    const method = "POST";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: fetchIdToken() || "",
      "x-api-key": apiKey || "",
    });
    const options = {
      method,
      headers,
      body: JSON.stringify({
        applicationIds,
      }),
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        dispatch(
          setHelpRequests({
            helpRequests: res.helpRequests,
          })
        );
      })
      .catch((error) => {
        console.log("error on getting Help Requests ", error);
      });
  };
};

export const resolveHelpRequest = (application, request) => {
  const { apiKey, baseRoute } = getConfigs();

  const route = `${baseRoute}/help-requests/${application.applicationId}/${request.id}/resolve`;

  return (dispatch) => {
    const method = "PUT";
    const headers = new Headers({
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: fetchIdToken() || "",
      "x-api-key": apiKey || "",
    });
    const options = {
      method,
      headers,
    };
    fetch(route, options)
      .then((data) => data.json())
      .then((res) => {
        if (res.error) {
          dispatch(
            setNotification({
              type: "ERROR",
              title: "Unable to process the Help Request",
              message:
                "There was an error processing the Help Request.\nPlease try again.",
            })
          );
        } else {
          dispatch(removeHelpRequest(request));
          // Removes help request from the Applications page
          window.localStorage.setItem(
            "HELP_REQUEST_RESOLVED",
            JSON.stringify(request)
          );
          window.localStorage.removeItem("HELP_REQUEST_RESOLVED");
          dispatch(
            setNotification({
              type: "INFO",
              message: `The Help Request was successfully resolved for ${application.unitId} ${request.helpRequestTopic}.`,
            })
          );
        }
      })
      .catch((error) => {
        console.log("error on getting Help Requests ", error);
        dispatch(
          setNotification({
            type: "ERROR",
            title: "Unable to process the Help Request",
            message:
              "There was an error processing the Help Request.\nPlease try again.",
          })
        );
      });
  };
};
