import ApplicationList from "../AdminDashboard/ApplicationList";
import { Filters } from "../AdminDashboard/Filters";
import ActionRequired from "../AdminDashboard/ActionRequired";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Alert } from "react-bootstrap";
import { resetNotification } from "../../store/actions";
import WarningModal from "../Shared/WarningModal";

const AdminDashboard = () => {
    const notification = useSelector(
        (state: RootStateOrAny) => state.notification
    );
    const dispatch = useDispatch();
    const closeNotification = () => {
        dispatch(resetNotification());
    };

    return (
        <div id="avb-admin-dashboard">
            <div className="admin-header">
                <Header email="" />
            </div>
            <div className="admin-body">
                <ActionRequired />
                <Filters />
                {notification && notification.type === "INFO" && (
                    <Alert
                        variant="success"
                        onClose={closeNotification}
                        dismissible
                    >
                        {notification.message}
                    </Alert>
                )}
                <ApplicationList />
                <WarningModal />
                <Footer />
            </div>
        </div>
    );
};
export default AdminDashboard;
