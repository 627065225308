import { Tag } from "antd";
import { EditFilled, ExclamationOutlined } from "@ant-design/icons";
import EllipsisIcon from "./icons/EllipsisIcon";
import InvitationIcon from "./icons/InvitationIcon";
import CreditReturnedIcon from "./icons/CreditReturnedIcon";
import ApprovedIcon from "./icons/ApprovedIcon";
import CompletionIcon from "./icons/CompletionIcon";
import CreditPendingIcon from "./icons/CreditPendingIcon";
import CancelIcon from "./icons/CancelIcon";
import ExclamationIcon from "./icons/ExclamationIcon";

const StatusTag = ({ name }) => {
    if (!name) return null;
    switch (name.toUpperCase()) {
        case "APPLICATION COMPLETE!": //"Application Complete!":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"green"} icon={<CompletionIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "ACCEPTED WITH CONDITIONS":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"orange"} icon={<ExclamationOutlined />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "DECLINED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"volcano"} icon={<CancelIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "CANCELLED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"volcano"} icon={<CancelIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "EXPIRED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"volcano"} icon={<CancelIcon />}>
                            ...{name}
                        </Tag>
                    </span>
                </div>
            );
        case "CREDIT AND ID RETURNED":
            return (
                <div>
                    <span title={name}>
                        <Tag
                            className="credit-pending"
                            icon={<CreditReturnedIcon />}
                        >
                            {name.replace("And", "&")}
                        </Tag>
                    </span>
                </div>
            );
        case "PENDING":

        case "CREDIT AND ID PENDING":
            return (
                <div>
                    <span title={name}>
                        <Tag
                            className="credit-pending"
                            icon={<CreditPendingIcon />}
                        >
                            Credit &amp; ID Pending
                        </Tag>
                    </span>
                </div>
            );
        case "IN PROGRESS":
            return (
                <div className="tag-in-progress">
                    <span title={name}>
                        <Tag color={"blue"} icon={<EllipsisIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "PROBLEM RESOLUTION":
            return (
                <div className="tag-in-problem-resolution">
                    <span title={name}>
                        <Tag color={"purple"} icon={<EllipsisIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "INVITE SENT":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"orange"} icon={<InvitationIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "APPROVED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"green"} icon={<ApprovedIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "FURTHER REVIEW":
            return (
                <div className="tag-further-review">
                    <span title={name}>
                        <Tag color={"blue"} icon={<EllipsisIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "APPROVED WITH CONDITIONS":
            return (
                <div className="">
                    <span title={name}>
                        <Tag color={"orange"} icon={<ExclamationIcon />}>
                            &nbsp;&nbsp;{name}
                        </Tag>
                    </span>
                </div>
            );

        case "APPROVED WITH CONDITIONS (CONDITIONS MET)":
            return (
                <div className="text-truncate">
                    <span title={name}>
                        <Tag color={"orange"} icon={<ExclamationIcon />}>
                            &nbsp;&nbsp;AWC, conditions met
                        </Tag>
                    </span>
                </div>
            );
        case "LEASE READY":
            return (
                <div>
                    <span>
                        <Tag
                            className="credit-pending"
                            icon={<CreditPendingIcon />}
                        >
                            Pending Lease Generation
                        </Tag>
                    </span>
                </div>
            );
        case "LEASE PENDING":
            return (
                <div>
                    <span>
                        <Tag
                            color={"orange"}
                            icon={
                                <EditFilled
                                    style={{
                                        color: "orange",
                                    }}
                                />
                            }
                        >
                            Pending Signature
                        </Tag>
                    </span>
                </div>
            );
        case "LEASE SIGNED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"green"} icon={<ApprovedIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        case "LEASE EXPIRED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"volcano"} icon={<CancelIcon />}>
                            Document Expired
                        </Tag>
                    </span>
                </div>
            );
        case "DOCUMENT EXPIRED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"volcano"} icon={<CancelIcon />}>
                            Document Expired
                        </Tag>
                    </span>
                </div>
            );
        case "LEASE DECLINED":
            return (
                <div>
                    <span title={name}>
                        <Tag color={"volcano"} icon={<CancelIcon />}>
                            {name}
                        </Tag>
                    </span>
                </div>
            );
        default:
            return (
                <Tag key={name} color={"blue"} icon={<ExclamationOutlined />}>
                    {name}
                </Tag>
            );
    }
};

export default StatusTag;
