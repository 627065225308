export const getConfigs = () => {
  const baseRoute = process.env.REACT_APP_ADMIN_DASHBOARD_API;
  const apiKey = process.env.REACT_APP_ADMIN_DASHBOARD_API_KEY;
  const cognitoHostedUiUrl = process.env.REACT_APP_COGNITO_HOSTED_UI_URL || "";
  const redirectToHostedUi =
    process.env.REACT_APP_REDIRECT_TO_HOSTED_UI === "false" ? false : true;

  const APPLICATION_HOLD_EXTEND_TIME_IN_MINUTES = 1440;
  const MILLISECONDS = 1000;
  const SECONDS = 60;
  const idleMinutes = 30;
  const defaultIdleTime = idleMinutes * SECONDS * MILLISECONDS;
  const REACT_APP_MAX_IDLE_TIME = process.env.REACT_APP_MAX_IDLE_TIME;
  const maxIdleTime = !REACT_APP_MAX_IDLE_TIME
    ? defaultIdleTime
    : parseInt(REACT_APP_MAX_IDLE_TIME);
  const minTokenTimeLeft = 15 * SECONDS * MILLISECONDS;
  const applicationHoldExtendTimeInMinutes =
    APPLICATION_HOLD_EXTEND_TIME_IN_MINUTES;

  const applicationHoldBaseRoute =
    process.env.REACT_APP_APPLICATION_HOLD_BASE_ROUTE;
  const applicationHoldApiKey = process.env.REACT_APP_APPLICATION_HOLD_API_KEY;
  const applicationBaseRoute = process.env.REACT_APP_APPLICATION_BASE_ROUTE;
  const applicationApiKey = process.env.REACT_APP_APPLICATION_API_KEY;

  return {
    baseRoute,
    apiKey,
    maxIdleTime,
    minTokenTimeLeft,
    applicationHoldExtendTimeInMinutes,
    cognitoHostedUiUrl,
    redirectToHostedUi,
    applicationHoldBaseRoute,
    applicationHoldApiKey,
    applicationBaseRoute,
    applicationApiKey,
  };
};
