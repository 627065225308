// NATIVE IMPORTS
require("./ApplicationList.scss");
import { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Table, Tooltip } from "antd";
import HoldClockStatus from "../Shared/HoldClockStatus";
import { formatDate } from "../../utils/Utils";
import {
  getRemainingTimeInMs,
  isApplicationStatusExpired,
  isClockExpired,
  isApplicationScreeningResultValid,
} from "../../utils/HoldClock";
import "antd/dist/antd.css";
import styled from "styled-components";

// SERVICES
import LocalStorageService from "../../services/LocalStorageService";

// COMPONENTS
import HelpRequestBadge from "./HelpRequestBadge";
import HoldClock from "../Shared/HoldClock";

// UTILITIES
import {
  filterBySearchAndAction,
  getStatusName,
  addFullNameAndlastUpdated,
} from "../../utils/Utils";
import { transferCredentials } from "../../utils/Auth";
import { ApplicantType } from "../../types/Applicant";
import { ApplicationStatusId } from "../../types/Application";

// ACTIONS
import { removeHelpRequest } from "../../store/actions";

// CUSTOM ICONS
import MagnifierGlassIcon from "../Shared/icons/MagnifierGlassIcon";
import StatusTag from "../Shared/StatusTag";
import { ApplicationStatus } from "src/types/ApplicationStatus";

const ApplicationList = () => {
  const applications = useSelector(
    (state: RootStateOrAny) => state.applications
  );
  const searchTerms = useSelector((state: RootStateOrAny) => state.searchTerms);
  const selectedActionId = useSelector(
    (state: RootStateOrAny) => state.selectedActionId
  );
  const isFetchingApplications = useSelector(
    (state: RootStateOrAny) => state.isFetchingApplications
  );
  const helpRequests = useSelector(
    (state: RootStateOrAny) => state.helpRequests
  );
  const filterOptions = useSelector(
    (state: RootStateOrAny) => state.filterOptions
  );

  const [isReadyToFilter, setIsReadyToFilter] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    function storageEvent(event) {
      if (event.key === "TAB_OPENED") {
        const email = window.sessionStorage.getItem("email");
        const token = window.sessionStorage.getItem("token");
        const data = JSON.stringify({ email, token });
        window.localStorage.setItem("SENDING_CREDENTIALS", data);
        window.localStorage.removeItem("SENDING_CREDENTIALS");
      } else if (event.key === "HELP_REQUEST_RESOLVED") {
        window.localStorage.getItem("HELP_REQUEST_RESOLVED");
        if (event.newValue) {
          const request = JSON.parse(event.newValue);
          dispatch(removeHelpRequest(request));
        }
      } else if (event.key === "REFRESH_APPS") {
        location.reload();
      }
    }
    window.addEventListener("storage", storageEvent);
    return () => window.removeEventListener("storage", storageEvent);
  }, []);

  useEffect(() => {
    // only run filtering when the applications are available
    if (applications && applications.length > 0) {
      setIsReadyToFilter(true);
    }
  }, [applications]);

  useEffect(() => {
    // Update localStorage when page refreshes
    LocalStorageService.updateLocalStorageApps(applications);
  }, [applications]);

  useEffect(() => {
    // Check for first time visitors
    if (!filterOptions) return;
    const { userFilterSelections } = filterOptions;
    const isNonFirstTimeVisitor =
      filterOptions &&
      filterOptions.userFilterSelections &&
      !!filterOptions.userFilterSelections.id;
    const hasCommunitiesSelections = !!userFilterSelections.communities;
    const hasMarketSelections = !!userFilterSelections.markets;
    const hasRegionSelections = !!userFilterSelections.regions;
    const hasNoSelections =
      !hasCommunitiesSelections && !hasMarketSelections && !hasRegionSelections;

    // show table as loading while filter options are being loaded
    if ((isNonFirstTimeVisitor || !hasNoSelections) && !filterOptions) {
      setShowTable(true);
      return;
    }

    // filter options loaded but is first time visitor
    if (!!filterOptions && !isNonFirstTimeVisitor) {
      setShowTable(false);
      return;
    }

    // returning visitor
    setShowTable(isNonFirstTimeVisitor && !hasNoSelections);
  }, [filterOptions]);

  const columns = [
    {
      title: "",
      dataIndex: "unitId",
      width: 30,
      className: "avb-help-request-col",
      render: (unit, application) => {
        const { applicationId } = application;
        const applicationRequests = helpRequests.filter(
          (h) => h.applicationId === applicationId
        );
        const requests =
          applicationRequests &&
          applicationRequests[0] &&
          applicationRequests[0].helpRequestList.sort((a, b) => {
            return (
              (a.modifiedDate && new Date(a.modifiedDate)) ||
              (a.createdDate && new Date(a.createdDate)) <
                (b.modifiedDate && new Date(b.modifiedDate)) ||
              (b.createdDate && new Date(b.createdDate))
            );
          });
        return (
          <div>
            {!!applicationRequests.length ? (
              <HelpRequestBadge
                helpRequests={requests}
                applicant={application}
              />
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Unit",
      dataIndex: "completeUnitId",
      width: 220,
      ellipsis: true,
      className: "avb-unit-col",
      sorter: (a, b) => {
        if (!a || a.completeUnitId == null) {
          return -1;
        }
        return a.completeUnitId.localeCompare(b.completeUnitId, "en", {
          numeric: true,
        });
      },
      sortDirection: ["descend", "ascend"],
      render: (unit, application) => {
        const { applicationId } = application;
        return (
          <div>
            <Link
              to={`/AdminDashboard/Application-Details/${applicationId}`}
              state={application}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p
                className="cstm-ellipse"
                title={`${unit}`}
                onClick={(e) => {
                  const _helpRequests = helpRequests.filter((x) => {
                    return x.applicationId == application.applicationId;
                  });

                  LocalStorageService.setApplicationInLocal(
                    application,
                    _helpRequests
                  );
                  transferCredentials();
                  e.stopPropagation();
                }}
              >
                {unit}
              </p>
            </Link>
          </div>
        );
      },
    },
    {
      title: "Primary Name",
      dataIndex: "fullName",
      width: 220,
      ellipsis: true,
      sorter: (a, b, sortOrder) => {
        if (sortOrder == "ascend") {
          const trimmedA = a.fullName.trimLeft();
          const trimmedB = b.fullName.trimLeft();
          if (trimmedA && !trimmedB) {
            return -1;
          } else if (!trimmedA && trimmedB) {
            return 1;
          }
        }

        return a.fullName.localeCompare(b.fullName, "en", {
          sensitivity: "base",
        });
      },
      sortDirection: ["descend", "ascend"],
      render: (fullName, application) => {
        if (application.fullName) {
          return fullName;
        }
        return;
      },
    },
    {
      title: "Community",
      dataIndex: "communityName",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => {
        if (!a || a.communityName === null) {
          return -1;
        }
        return a.communityName.localeCompare(b.communityName);
      },
      sortDirection: ["descend", "ascend"],
    },
    {
      title: "Last Updated",
      width: 180,
      dataIndex: "lastUpdated",
      ellipsis: true,
      sorter: (a, b) => {
        if (!a || a.lastUpdated) {
          return -1;
        }
        return (
          new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime()
        );
      },
      sortDirection: ["descend", "ascend"],
      render: (date) => {
        if (date) {
          const d = new Date(date);
          const dateString = d
            .toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
            })
            .toLowerCase();
          return dateString;
        }
      },
    },
    {
      title: "Status",
      width: 220,
      ellipsis: true,
      dataIndex: "applicationStatus",
      sorter: (a, b) => {
        if (!a || a.applicationStatus === null) {
          return -1;
        }
        return a.applicationStatus.localeCompare(b.applicationStatus);
      },
      sortDirection: ["descend", "ascend"],
      className: "avb-status-col",
      render: (status, application) => {
        if (isApplicationStatusExpired(status, application)) {
          status = ApplicationStatus.EXPIRED;
        }

        const { applicationHold } = application;
        const isHoldClock = Boolean(
          applicationHold && applicationHold?.holdDate
        );
        let remainingTime;
        if (isHoldClock && status !== ApplicationStatus.EXPIRED) {
          const holdDateFormatted = new Date(applicationHold.holdDate);
          remainingTime = getRemainingTimeInMs(holdDateFormatted);
          if (
            status == ApplicationStatus.IN_PROGRESS &&
            isClockExpired(remainingTime)
          ) {
            status = ApplicationStatus.EXPIRED;
          }
        }
        if (
          application.applicants.find(
            (applicant) =>
              applicant.applicationProcess.applicationStatusId ===
              ApplicationStatusId.ActionRequired
          )
        ) {
          status = ApplicationStatus.ACTION_REQUIRED;
        }
        return (
          <HoldClockStatus status={status} remainingTime={remainingTime} />
        );
      }, //getTag(status)
    },
    {
      title: "Move-In",
      width: 150,
      ellipsis: true,
      dataIndex: "moveInDate",
      sorter: (a, b) => {
        if (!a || a.moveInDate === null) {
          return -1;
        }
        return (
          new Date(a.moveInDate).getTime() - new Date(b.moveInDate).getTime()
        );
      },
      sortDirection: ["descend", "ascend"],
      className: "avb-move-in-col",
      render: (date) => {
        if (date) {
          return formatDate(date);
        }
        return;
      },
    },
    {
      title: "Hold Clock",
      width: 150,
      dataIndex: "applicationHold",
      ellipsis: true,
      className: "avb-hold-clock-col",
      sorter: (a, b) => {
        var isAHoldClock: boolean =
          a && a.applicationHold && a.applicationHold.holdDate;
        var isBHoldClock: boolean =
          b && b.applicationHold && b.applicationHold.holdDate;

        const isAScreeningValid = isApplicationScreeningResultValid(
          a.applicationScreeningResult
        );
        const isBScreeningValid = isApplicationScreeningResultValid(
          b.applicationScreeningResult
        );

        if (!isAHoldClock && !isBHoldClock) {
          return 0;
        }

        if (
          (isAHoldClock && !isBHoldClock) ||
          (isAScreeningValid && !isBScreeningValid)
        ) {
          return 1;
        }

        if (
          (!isAHoldClock && isBHoldClock) ||
          (!isAScreeningValid && isBScreeningValid)
        ) {
          return -1;
        }

        return (
          new Date(a.applicationHold.holdDate).getTime() -
          new Date(b.applicationHold.holdDate).getTime()
        );
      },
      sortDirection: ["descend", "ascend"],
      render: (fullName, application) => {
        return <HoldClock application={application} type={"list"} />;
      },
    },
    {
      title: "Group Outcome",
      width: 200,
      ellipsis: true,
      dataIndex: "applicationScreeningResult",
      sorter: (a, b) => {
        if (a === b) {
          return 0;
        }
        if (!a || a.applicationScreeningResult === null) {
          return 1;
        }
        if (!b || b.applicationScreeningResult === null) {
          return -1;
        }
        return a.applicationScreeningResult.localeCompare(
          b.applicationScreeningResult
        );
      },
      sortDirection: ["descend", "ascend"],
      className: "avb-group-outcome-col",
      render: (unit, application) => {
        const { applicationScreeningResult } = application;

        return applicationScreeningResult ? (
          <>
            <StatusTag name={applicationScreeningResult} />
          </>
        ) : null;
      },
    },
  ];

  const getSpinner = () => {
    return <Spinner animation="border" role="status"></Spinner>;
  };

  const isReady = showTable && isReadyToFilter;

  return (
    <div id="avb-application-list">
      {showTable ? (
        <Table
          dataSource={
            isReady &&
            applications &&
            filterBySearchAndAction(
              addFullNameAndlastUpdated(applications),
              searchTerms,
              selectedActionId
            )
          }
          columns={columns}
          pagination={{ defaultPageSize: 20 }}
          loading={{
            spinning: isFetchingApplications,
            indicator: <div>{getSpinner()}</div>,
          }}
          bordered
          scroll={{ x: 1300 }}
          expandable={{
            rowExpandable: (record) => {
              const { applicants } = record;
              const count = applicants.length;
              return count > 1; // expand if more than one tenant
            },
            expandedRowRender: (record) => renderExpandedRow(record),
          }}
          rowKey="applicationId"
        />
      ) : (
        <div className="first-time-user">
          <MagnifierGlassIcon />
          <div>
            {" "}
            Please search by <b>region</b>, <b>market</b> or <b>community</b>
          </div>
        </div>
      )}
    </div>
  );
};

const sortByApplicantTypeAndName = (a, b) => {
  // Order by primary (1), cotenant (2) then guarantor (3)
  if (a.applicantTypeId > b.applicantTypeId) return 1;
  if (a.applicantTypeId < b.applicantTypeId) return -1;
  // same - order by name
  if (a.applicantTypeId == b.applicantTypeId) {
    if (a.firstName < b.firstName) return -1;
    if (a.firstName > b.firstName) return 1;
  }

  return 0;
};
const sortByApplicationStatus = (a, b) => {
  if (
    a.applicationProcess.applicationStatusId ===
      ApplicationStatusId.ActionRequired &&
    b.applicationProcess.applicationStatusId ===
      ApplicationStatusId.CreditAndIDPending
  ) {
    return -1;
  }
  if (
    b.applicationProcess.applicationStatusId ===
      ApplicationStatusId.ActionRequired &&
    a.applicationProcess.applicationStatusId ===
      ApplicationStatusId.CreditAndIDReturned
  ) {
    return -1;
  }

  return 0;
};
const renderExpandedRow = (record) => {
  const { applicants } = record;
  const sortedApplicants = applicants.sort(sortByApplicantTypeAndName);

  sortedApplicants.forEach((applicant) => {
    const { firstName, lastName } = applicant;
    if (
      applicant.applicantTypeId == ApplicantType.Applicant ||
      applicant.applicantTypeId == ApplicantType.CoTenant
    ) {
      applicant["displayName"] = `${firstName} ${lastName}`;
    } else {
      applicant["displayName"] = `${firstName} ${lastName}`; //(Guarantor ${guarantorCount})
    }
  });

  const expandedRowColumns = [
    {
      width: 30,
      ellipsis: true,
    },
    {
      width: 220,
      ellipsis: true,
    },
    {
      title: "Primary Name",
      dataIndex: "displayName",
      width: 220,
      ellipsis: true,
      render: (displayName, applicant) => {
        return (
          <>
            {displayName}
            {applicant.applicantTypeId == ApplicantType.Guarantor && (
              <Tooltip
                placement="top"
                title="Guarantor"
                color={"#2a3244"}
                mouseEnterDelay={0}
                overlayClassName="help-request-email-tooltip"
              >
                <span className="avb-g-tag">G</span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      width: 200,
      ellipsis: true,
    },
    {
      width: 180,
      ellipsis: true,
    },
    {
      title: "Status",
      width: 220,
      dataIndex: "applicationProcess",
      ellipsis: true,
      render: (applicationProcess) => {
        if (applicationProcess?.applicationStatusId == null) {
          return null;
        }
        return getStatusName(applicationProcess?.applicationStatusId);
      },
    },
    {
      width: 150,
      ellipsis: true,
    },
    {
      width: 150,
      ellipsis: true,
    },
    {
      width: 200,
      ellipsis: true,
    },
  ];

  const ExpandedRow = styled(Table)`
    .ant-table-tbody > tr > td {
      border: none;
      padding-top: 0;
      padding-bottom: 0;
    }
    ,
    .ant-table-wrapper {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `;

  return (
    <ExpandedRow
      rowClassName={() => "avb-exapnded-row"}
      bordered={false}
      pagination={false}
      showHeader={false}
      showSorterTooltip={false}
      columns={expandedRowColumns}
      dataSource={sortedApplicants
        .filter((x) => !!x.firstName && !!x.lastName)
        .sort(sortByApplicationStatus)}
    ></ExpandedRow>
  );
};

export default ApplicationList;
